import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout.js';
import {HeroTitleH1} from '../components/typography.js';
import {Section} from '../components/sections/sections.js'

const Title = styled(HeroTitleH1)`
  font-size: 130px;
  margin: 0 auto;
`
const GuidedTour = () => {

  return (
    <Layout>
      <Section>
      <Title>Let us guide you.</Title>
      </Section>
    </Layout>
  )
}

export default GuidedTour